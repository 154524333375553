import { useParams, Navigate } from "react-router-dom";
import { useEffect } from "react";
import ArrayProjet from "../../data/projet.json";
import Difficulty from "../../components/difficulty/difficulty";
import Slider from "../../components/slider/slider";
import ImggitCode from "../../asset/GitCode.png";
import ImggitPage from "../../asset/GitPage.png";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function Project() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = "Fiche Projet ";
  });

  const { slug } = useParams();
  const MyProject = ArrayProjet.find((projet) => projet.slug === slug);
  if (!MyProject) {
    return <Navigate to="/error" />;
  }

  console.log(MyProject);
  const {
    description,
    difficulty,
    title,
    github,
    languageImg,
    pictures,
    gitPages,
    gitClass,
    presentationLink,
    presentationVideo,
  } = MyProject;
  console.log(difficulty);

  return (
    <>
      <Header />
      <div className="centerPages" id="top">
        <article className="projetContainer">
          <div className="titleContainer">
            <h1 className={slug}>{title}</h1>
          </div>
          <div className="sliderContainer" id={slug}>
            <Slider slides={pictures} />

            <video
              className="video"
              muted
              autoplay="true"
              src={pictures}
              alt="video du carrousel"
            />
          </div>

          <div className="descriptionContainer">
            <p>{description}</p>
          </div>
          <section className="line">
            <div className="difficultyContainer">
              <p>Difficultés: </p>
              <Difficulty Rate={difficulty} />
            </div>

            <div className="langageContainer">
              <h2>Language / FrameWorks </h2>
              <div className="imgLangage">
                {languageImg.map((langue, index) => (
                  <p key={index}>
                    <div>
                      <img src={langue} alt="Language Utilisé" />
                    </div>
                  </p>
                ))}
              </div>
            </div>
          </section>
          <div className="repoContainer">
            <div>
              <h2>Liens utile :</h2>
              <a
                href={github}
                alt="lien github"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ImggitCode} alt={github} />
              </a>
              <a
                className={gitClass}
                id={slug}
                href={gitPages}
                alt="lien github"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ImggitPage} alt={gitPages} />
              </a>

              <div className={presentationLink}>
                <video
                  className=""
                  controls
                  muted
                  src={presentationVideo}
                  alt="video de Presentation"
                />
              </div>
            </div>
          </div>
        </article>
      </div>
      <Footer />
    </>
  );
}

export default Project;
