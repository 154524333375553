import { useEffect } from "react";

import StoredImages from "../../components/storedImage/storedImage";

function SliderBDD() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Contact`;
  });
  return (
    <div className="SliderSize">
      <StoredImages />
    </div>
  );
}

export default SliderBDD;
