import linkedin from "../../asset/linkedin.png";
import ImggitCode from "../../asset/GitCode.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footerInfo">
        <p>©2023 MARTIAUX Alan. Tous droits réservés.</p>
      </div>

      <div className="socialLink">
        <div>
          <Link to="/mentions" className="footer_mentions">
            Mentions Légales
          </Link>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/alan-martiaux-925482114/"
            alt="lien linkedin"
          >
            <img src={linkedin} alt="Lien Linkedin" />
          </a>
          <a
            href="https://github.com/Alan-Martiaux?tab=repositories"
            alt="Repertoire Github"
          >
            <img src={ImggitCode} alt="Lien Repertoire GitHub" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
