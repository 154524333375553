import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Card({ title, cover, slug, carddescription, animation, iconP }) {
  return (
    <article className="Card" id={animation}>
      <Link to={`/${slug}`}>
        <div className="ContainerCard">
          <img src={cover} alt="Projet" className="img_project_card"></img>
          <div className="cardbackground">
            <div className="CardMore">
              <p>{carddescription}</p>
            </div>
          </div>
          <h1 className="cardTitle">{title}</h1>
        </div>
      </Link>
    </article>
  );
}

Card.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  picture: PropTypes.string,
};

export default Card;
