import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./sass/style.css";
import reportWebVitals from "./reportWebVitals";
//import Header from "./components/header/header";
import Home from "./view/Home/home";
//import Footer from "./components/footer/footer";
import Project from "./view/Project/project";
import Error from "./view/error/error";
import CV from "./view/CV/cv";
import Contact from "./view/contact/contact";
import Mentions from "./view/mentions/mentions";
import Bapteme from "./view/bapteme/home";
import SliderBDD from "./view/bapteme/sliderbapt";
import SendPicture from "./view/bapteme/sendPicture";
import PageChoice from "./view/bapteme/PageChoice";
import SendPictureMika from "./view/bapteme/sendbyMika";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      {/* Header en dehors du Route pour qu'il soit présent sur toutes les pages sauf bapteme */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/error" element={<Error />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/:slug" element={<Project />} />
        <Route path="/CV" element={<CV />} />
        <Route path="/mentions" element={<Mentions />} />

        {/* Route pour le routeur secondaire */}
        <Route path="/bapteme" element={<PageChoice />} />
        <Route path="/gazette" element={<Bapteme />} />
        <Route path="/SliderBDD" element={<SliderBDD />} />
        <Route path="/sendpicture" element={<SendPicture />} />
        <Route path="/sendbyMika" element={<SendPictureMika />} />
      </Routes>
      {/* Footer en dehors du Route pour qu'il soit présent sur toutes les pages sauf bapteme */}
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
