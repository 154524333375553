import { useEffect } from "react";

import { ContactUs } from "../../components/contact/contactForm";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function Contact() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Contact`;
  });
  return (
    <>
      <Header />
      <div className="contactForm">
        <h1>Contactez-moi ! </h1>
        <ContactUs />
      </div>
      <Footer />
    </>
  );
}

export default Contact;
