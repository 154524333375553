import { useEffect } from "react";

import UploadImageMika from "../../components/uploadImage/uploadMika";

function SendPictureMika() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Contact`;
  });
  return <UploadImageMika />;
}

export default SendPictureMika;
