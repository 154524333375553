import React, { useState } from "react";
import Vide from "../../asset/vide.png";
import Reponse from "../../asset/reponse.png";

const ImgVide = Vide;
const ImgReponse = Reponse;

const ImageChanger = () => {
  // Définir un état pour stocker l'URL de l'image actuelle
  const [imageUrl, setImageUrl] = useState(ImgVide);
  const [isReponseVisible, setIsReponseVisible] = useState(false);

  // Fonction pour changer l'image lors du clic sur le bouton
  const toggleImage = () => {
    // Si l'image actuelle est celle de la réponse, revenir à l'image vide
    if (isReponseVisible) {
      setImageUrl(ImgVide);
      setIsReponseVisible(false);
    } else {
      setImageUrl(ImgReponse);
      setIsReponseVisible(true);
    }
  };

  return (
    <div className="MotCroise">
      <button className="bapteme_button" onClick={toggleImage}>
        {isReponseVisible ? "Cacher la solution" : "Afficher la solution"}
      </button>
      <img src={imageUrl} alt="Image" />
    </div>
  );
};

export default ImageChanger;
