import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import feeRE from "../../asset/feeRE.png";
function PageChoice() {
  useEffect(() => {
    document.title = `Baptême`;
  }, []);
  useEffect(() => {
    // Obtenez l'élément cible en utilisant son ID
    const targetElement = document.getElementById("centerChoice");

    // Vérifiez si l'élément cible existe
    if (targetElement) {
      // Faites défiler la fenêtre jusqu'à l'élément cible
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  }, []); // Le deuxième argument vide [] signifie que cette fonction ne sera exécutée qu'une seule fois après le premier rendu

  return (
    <>
      <div className="PageChoice bapteme_background">
        <img className="back_img" src={feeRE} alt="Flêche gauche" />
        <section className="">
          <h2>La Gazette de Rose-Elia</h2>
          <p>Clique sur le bouton ci-dessous si tu veux :</p>
          <p>Accéder à la Gazette de Rose-Elia en format numérique !</p>

          <button className="bapteme_button scintillate">
            <Link to="/gazette">La Gazette de Rose-Elia</Link>
          </button>
        </section>
        <div id="centerChoice"></div>
        <section className="">
          <h2>Affichage de photos !</h2>
          <p>Clique sur le bouton ci-dessous si tu veux :</p>
          <p>Afficher tes photos du baptême sur l'écran du bar !</p>
          <button className="bapteme_button scintillate">
            <Link to="/sendpicture">Envoyer des Photos</Link>
          </button>
        </section>
      </div>
    </>
  );
}

export default PageChoice;
