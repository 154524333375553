import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const down = <FontAwesomeIcon icon={faChevronDown} />;
const up = <FontAwesomeIcon icon={faChevronUp} />;
const location = <FontAwesomeIcon icon={faLocationDot} />;
const information = <FontAwesomeIcon icon={faCircleInfo} />;
const calendar = <FontAwesomeIcon icon={faCalendarDays} />;

const Collapse = ({
  title,
  content,
  date,
  lieux,

  children,
}) => {
  const [openTab, setOpenTab] = useState(false);

  const openTabHandler = () => {
    setOpenTab((openTab) => !openTab);
  };

  return (
    <section className="collapse">
      <div onClick={openTabHandler} className="collapse_header">
        <h4 className="collapse_header_title">{title}</h4>

        {!openTab ? (
          <i className="collapse_header_icon arrowUp">{up}</i>
        ) : (
          <i className="collapse_header_icon arrowDown">{down}</i>
        )}
      </div>
      {openTab && (
        <div className="collapse_content">
          <li>
            <i>{calendar}</i>
            {date}
          </li>
          <li>
            <i>{location}</i> {lieux}
          </li>
          <li>
            <i>{information}</i>
            {content}
          </li>

          {children}
        </div>
      )}
    </section>
  );
};

export default Collapse;
