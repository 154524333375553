import React from "react";
import ArrayProject from "../../data/projet";
import { useEffect } from "react";
import Card from "../../components/card/card";
import { Link } from "react-router-dom";
import MaPhoto from "../../asset/maphoto.webp";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function Home() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Accueil`;
  });

  return (
    <>
      <Header />
      <section className="presentation" id="top">
        <h1>Qui suis-je ?</h1>
        <article className="gridPresentation">
          <div>
            <img src={MaPhoto} alt="Alan MARTIAUX" />
          </div>
          <div className="moi">
            <p>
              Je suis Alan Martiaux, je suis actuellement en formation
              d'intégrateur WEB !
            </p>

            <Link to="/CV">
              Pour en savoir plus sur mes compétences, vous pouvez aller voir
              mon CV.
            </Link>

            <p>
              Et pour voir mes différents projets réalisés vous trouverai
              ci-dessous quelques exemples
            </p>
          </div>
        </article>
      </section>
      <section className="gallery">
        {ArrayProject.map((projet, index) => (
          <Card
            key={index}
            title={projet.title}
            cover={projet.cover}
            slug={projet.slug}
            carddescription={projet.carddescription}
            animation={projet.animation}
            iconP={projet.iconP}
          />
        ))}
      </section>
      <Footer />
    </>
  );
}

export default Home;
