import Logo from "../../asset/LOGO.webp";
import { Link } from "react-router-dom";

function Header({ bapteme }) {
  console.log("Baptême est à", bapteme);

  return (
    <header>
      <div className="headerContainer">
        <Link to="/">
          <img className="img_logo" src={Logo} alt="LOGO" />
        </Link>
        <nav>
          <Link to="/">Accueil</Link>
          <Link to="/CV">CV</Link>
          <Link to="/contact">Contact</Link>
        </nav>
      </div>
    </header>
  );
}

export default Header;
