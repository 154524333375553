import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function Mentions() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Mentions Legales`;
  });
  return (
    <>
      <Header />
      <div className="mentions">
        <h1>MENTIONS LEGALES</h1>
        <h2>En vigueur au 12/12/2023</h2>
        <p>
          {" "}
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          utilisateurs et visiteurs ", du site alanmartiaux.fr , ci-après le
          "Site", les présentes mentions légales. La connexion et la navigation
          sur le Site par l’Utilisateur implique acceptation intégrale et sans
          réserve des présentes mentions légales. Ces dernières sont accessibles
          sur le Site à la rubrique « Mentions légales ».
        </p>

        <h2>ARTICLE 1 - L'EDITEUR</h2>
        <p>
          L’édition et la direction de la publication du Site est assurée par
          MARTIAUX ALAN, domiciliée 109 RUE DE RABAT, et l'adresse e-mail
          contact@alanmartiaux.fr.
        </p>

        <h2>ARTICLE 2 - L'HEBERGEUR</h2>
        <p>
          L'hébergeur du Site est la société OVH, dont le siège social est situé
          au 2 rue Kellermann 59100 Roubaix{" "}
        </p>

        <h2>ARTICLE 3 - ACCES AU SITE</h2>
        <p>
          Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
          majeure, interruption programmée ou non et pouvant découlant d’une
          nécessité de maintenance. En cas de modification, interruption ou
          suspension du Site, l'Editeur ne saurait être tenu responsable.
        </p>
        <h2>ARTICLE 4 - COLLECTE DES DONNEES</h2>
        <p>
          Le site est exempté de déclaration à la Commission Nationale
          Informatique et Libertés (CNIL) dans la mesure où il ne collecte
          aucune donnée concernant les utilisateurs. Toute utilisation,
          reproduction, diffusion, commercialisation, modification de toute ou
          partie du Site, sans autorisation de l’Editeur est prohibée et pourra
          entraînée des actions et poursuites judiciaires telles que notamment
          prévues par le Code de la propriété intellectuelle et le Code civil.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Mentions;
