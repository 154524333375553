import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sqarcul",
        "template_0sud1jf",
        form.current,
        "AC_IggY52Un7jIfWa"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          alert(`Message bien envoyé !`);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="contactForm">
      <form ref={form} onSubmit={sendEmail}>
        <label>Nom</label>
        <input className="user" type="text" name="user_name" required />
        <label>Email</label>
        <input className="user" type="email" name="user_email" required />
        <label>Message</label>
        <textarea className="user_text" name="message" />
        <div className="div_button_form">
          <input
            className="form_button"
            type="submit"
            value="Envoyer"
            required
          />
        </div>
      </form>
    </section>
  );
};
