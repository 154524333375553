import { useEffect } from "react";

import UploadImage from "../../components/uploadImage/uploadImage";

function SendPicture() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Contact`;
  });
  return <UploadImage />;
}

export default SendPicture;
