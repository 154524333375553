import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { Link } from "react-router-dom";

import right from "../../asset/rightbap.webp";
import left from "../../asset/leftbap.webp";
import fee from "../../asset/fee.png";

const UploadImage = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [nonAllowedVideos, setNonAllowedVideos] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);

  useEffect(() => {
    document.title = `Envoi`;
  });

  useEffect(() => {
    // Cette fonction sera exécutée lorsque le composant sera monté
    window.scrollTo(0, 0);
  }, []); // Le deuxième argument vide [] signifie que cette fonction ne sera exécutée qu'une seule fois après le premier rendu

  const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

  const handleImageChange = (e) => {
    const files = e.target.files;
    const imagesArray = Array.from(files).filter((file) => {
      if (file.type.indexOf("video/") === 0) {
        // Log non-allowed videos
        console.log(`Vidéo non autorisée: ${file.name}`);
        setNonAllowedVideos((prevNonAllowedVideos) => [
          ...prevNonAllowedVideos,
          file.name,
        ]);
        setTimeout(() => {
          setNonAllowedVideos([]);
        }, 5000); // Effacer le message après 5 secondes
        return false;
      }
      return allowedImageTypes.includes(file.type);
    });

    if (imagesArray.length > 0) {
      setIsImageSelected(true);
    } else {
      setIsImageSelected(false);
    }

    setSelectedImages(imagesArray);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === selectedImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedImages.length - 1 : prevIndex - 1
    );
  };

  const handleUpload = async () => {
    // Logique d'envoi des images à Firebase Storage
    const storageRef = firebase.storage().ref();

    const getImageCount = async () => {
      const storageRef = firebase.storage().ref("images");
      const images = await storageRef.listAll();
      return images.items.length;
    };

    const count = await getImageCount();

    if (selectedImages.length > 0) {
      selectedImages.forEach((image, index) => {
        // Ajouter 170000 à l'index de chaque image téléchargée
        const newImageName = `image_${count + index + 1 + 170000}`;
        const imageRef = storageRef.child(`images/${newImageName}`);

        imageRef
          .put(image)
          .then((snapshot) => {
            console.log("Image téléversée avec succès :", newImageName);
            // Après le téléversement réussi, récupérer l'URL de l'image depuis Firebase Storage
            imageRef.getDownloadURL().then((url) => {
              console.log("URL de l'image :", url);
            });
          })
          .catch((error) => {
            console.error(
              "Erreur lors du téléversement de l'image",
              newImageName,
              ":",
              error
            );
          });
      });

      // Une fois toutes les images téléversées, afficher un message de confirmation
      setUploadSuccess(true);
      setSelectedImages([]); // Réinitialiser le tableau des images après l'envoi
      document.getElementById("imageInput").value = ""; // Réinitialiser le champ de fichier
      setIsImageSelected(false); // Réafficher le bouton "Aller sur La Gazette de Rose-Elia"
      setTimeout(() => {
        setUploadSuccess(false);
      }, 3000); // Cacher le message après 3 secondes
    } else {
      console.error("Aucune image sélectionnée pour le téléversement.");
    }
  };

  return (
    <div className="bapteme_background ">
      <img className="back_img" src={fee} alt="Flêche gauche" />

      <h2 style={{ color: "#d7afda" }}>Envoi d'image sur l'écran</h2>
      <label htmlFor="imageInput" className="custom-file-upload">
        Sélectionner des images
        <input
          id="imageInput"
          type="file"
          onChange={handleImageChange}
          multiple
        />
      </label>
      {/* Affichage de l'image sélectionnée */}
      {selectedImages.length > 0 && (
        <section>
          <h3>Image sélectionnée :</h3>
          <div
            className="upload_bapteme"
            style={{ display: "flex", alignItems: "center" }}
          >
            {selectedImages.length > 1 && (
              <img
                className="arrow_bapteme"
                onClick={handlePreviousImage}
                src={left}
                alt="Flêche gauche"
              />
            )}
            <img
              src={URL.createObjectURL(selectedImages[currentImageIndex])}
              alt={`Image ${currentImageIndex}`}
              style={{ width: "100px", height: "auto" }}
            />
            {selectedImages.length > 1 && (
              <img
                className="arrow_bapteme"
                onClick={handleNextImage}
                src={right}
                alt="Flêche droite"
              />
            )}
          </div>
        </section>
      )}
      {selectedImages.length > 0 && (
        <button className="custom-file-upload " onClick={handleUpload}>
          Envoyer
        </button>
      )}
      {uploadSuccess && (
        <p style={{ color: "green", marginTop: "10px" }}>
          Envoi réussi ! Ton image s'affichera dès que possible !
        </p>
      )}

      {nonAllowedVideos.length > 0 && (
        <div>
          <p style={{ color: "red", marginTop: "10px", textAlign: "center" }}>
            Les vidéos ne sont pas autorisées !
          </p>
          <p>Mais tu peux nous l'envoyer par e-mail</p>
          <a
            className="custom-file-upload "
            href="mailto:alan.martiaux@gmail.com?subject=Sujet de l'email&body=Corps de l'email"
          >
            Envoyer par e-mail
          </a>
        </div>
      )}

      {!isImageSelected && (
        <button className="bapteme_button send_button scintillate">
          <Link to="/gazette">Aller sur La Gazette de Rose-Elia</Link>
        </button>
      )}
    </div>
  );
};

export default UploadImage;
