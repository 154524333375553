import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const Slider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    document.title = `Slider`;
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3500); // Changement d'image toutes les 5 secondes

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="SliderBDD">
      {images.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Image ${index}`}
          style={{
            display: index === currentImageIndex ? "block" : "none",
          }}
        />
      ))}
    </div>
  );
};

const StoredImages = () => {
  const [storedImages, setStoredImages] = useState([]);

  const fetchStoredImages = async () => {
    try {
      const storageRef = firebase.storage().ref();
      const imagesRef = storageRef.child("images");

      const imagesSnapshot = await imagesRef.listAll();
      const urls = await Promise.all(
        imagesSnapshot.items.map(async (item) => {
          return await item.getDownloadURL();
        })
      );

      setStoredImages(urls);
    } catch (error) {
      console.error("Erreur lors de la récupération des images :", error);
    }
  };

  useEffect(() => {
    // Appeler la fonction fetchStoredImages immédiatement
    fetchStoredImages();

    // Définir un intervalle pour vérifier le stockage toutes les 10 secondes
    const interval = setInterval(fetchStoredImages, 10000);

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, []);

  return <Slider images={storedImages} />;
};

export default StoredImages;
