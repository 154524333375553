function HeaderRe() {
  return (
    <header className="headerRE">
      <div className="headerContainerRE">
        <div className="title">
          <h1>LA GAZETTE DE ROSE-ELIA</h1>
        </div>
        <h3 className="headerdate">27 Avril 2024</h3>
      </div>
    </header>
  );
}

export default HeaderRe;
