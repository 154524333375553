import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const emptyStar = <FontAwesomeIcon icon={faStar} />;
const solidStar = <FontAwesomeIcon icon={faStar} />;

function Difficulty(diff) {
  const DiffRate = diff.Rate;
  const nbDiff = [1, 2, 3, 4, 5];

  return (
    <div>
      {nbDiff.map((difficult, index) =>
        DiffRate >= difficult ? (
          <i key={index}>{solidStar}</i>
        ) : (
          <i key={index} className="empty">
            {emptyStar}
          </i>
        )
      )}
    </div>
  );
}

export default Difficulty;
