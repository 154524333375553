import React from "react";
import { Link } from "react-router-dom";

import { useEffect } from "react";
import HeaderRe from "../../components/headerRE/header";

import RoseElia from "../../asset/Rose-Elia.png";
import Lion from "../../asset/lion.png";
import Parrain from "../../asset/parrain.png";
import Marraine from "../../asset/marraine.png";
import Laby from "../../asset/laby.png";
import ImageChanger from "../../components/mot/mot";
import ImageChangerLaby from "../../components/mot/laby";
import BebeFou from "../../asset/bebefou.png";
import Soleil from "../../asset/soleil.png";

import UploadImage from "../../components/uploadImage/uploadImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faScaleUnbalanced,
  faClock,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";

import ScrollButton from "../../components/storedImage/real";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "VotreApiKey",
  authDomain: "rose-elia.firebaseapp.com",
  projectId: "rose-elia",
  storageBucket: "rose-elia.appspot.com",
  messagingSenderId: "VotreSenderId",
  appId: "VotreAppId",
  measurementId: "VotreMeasurementId",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
firebase.initializeApp(firebaseConfig);

const balance = (
  <FontAwesomeIcon icon={faScaleUnbalanced} style={{ fontSize: "40px" }} />
);

const calendar = (
  <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: "40px" }} />
);

const clock = <FontAwesomeIcon icon={faClock} style={{ fontSize: "40px" }} />;

const ruler = <FontAwesomeIcon icon={faRuler} style={{ fontSize: "40px" }} />;

function Bapteme() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Gazette`;
  });

  let bapteme = true;
  console.log(bapteme);

  return (
    <>
      <HeaderRe />

      <div className="bapteme_background">
        <article className="part_sub">
          <div className="line_dark"></div>
          <h3 className="subtitle">C'EST LE GRAND JOUR POUR MON BAPTÊME !</h3>
          <div className="line_dark"></div>
        </article>
        <article className="part_info">
          <div className="info_picture">
            <section className="wait_picture">
              <img src={RoseElia} alt="Rose-Elia" />
            </section>
            <p className="subtitle">ROSE-ELIA</p>
            <section className="info_born">
              <article>
                <p>
                  <i className="born_icon">{calendar}</i> 15 août 2023
                </p>
                <p>
                  <i className="born_icon">{balance}</i>2kg880
                </p>
              </article>
              <article>
                {" "}
                <p>
                  <i className="born_icon">{clock}</i>15h35
                </p>
                <p>
                  <i className="born_icon">{ruler}</i>49cm
                </p>
              </article>
            </section>
          </div>
          <div className="info_more">
            <section>
              {" "}
              <h3>CE QUI C'EST PASSÉ LE 15 AOÛT</h3>
              <p>
                L'Assomption, elle est célébrée le 15 août et fréquemment
                accompagnée de processions. Elle commémore la gloire de Marie
                avec Dieu au terme de sa vie terrestre, autrement dit le moment
                où la Vierge Marie, mère de Jesus, est "montée au ciel".
              </p>
              <article className="Meteo_astro">
                <div className="meteo">
                  <h3>Petit point météo :</h3>
                  <p>Mardi 15 Août 2023</p>
                  <img src={Soleil} alt="soleil" />
                </div>
                <div className="astro_container">
                  <h3>MON SIGNE ASTROLOGIQUE</h3>{" "}
                  <img className="astro_lion" src={Lion} alt="Lion" />
                </div>
              </article>
              <div className="bebe_fou">
                <p>
                  Je suis née un mardi, j'ai eu ma première dent en février. Je
                  suis une petite fille pleine de vie, la preuve :
                </p>
                <img src={BebeFou} alt="Rose-Elia avec des lunettes" />
              </div>
            </section>
          </div>
        </article>
        <div className="pink_line"></div>
        <article className="parrain_marraine">
          <section className="parrain">
            <p className="PM_txt">
              Mes parents t'ont choisi car tu es le frère de mon papa et qu'ils
              savent que tu seras là pour moi, tu seras la pour m'aider à faire
              des bêtises ou m'apprendre à jouer au foot.{" "}
            </p>
            <div className="wait_picture_PM">
              <img src={Parrain} alt="Dylan le parrain" />
            </div>
          </section>
          <section className="marraine">
            <div className="wait_picture_PM">
              <img src={Marraine} alt="Caroline la marraine" />
            </div>
            <p className="PM_txt">
              Mes parents t'ont choisi car tu es proche de ma maman, pour ta
              gentillesse et ta générosité. Ils savent que tu seras là dans les
              moments importants et que tu m'apprendras beaucoup.{" "}
            </p>
          </section>
        </article>
        <div className="pink_line"></div>
        <article className="part_sub">
          <h3 className="subtitle_two">Aide moi à retrouver mon doudou !</h3>
          <ImageChangerLaby />
        </article>
        <div className="pink_line"></div>
        <article className="part_Mot">
          <h3 className="subtitle_tree">Les Mots croisé de Rose-Elia</h3>
          <h4>Connais-tu réellement Rose-Elia ?</h4>
          <ImageChanger />
        </article>
        <button className="bapteme_button scintillate">
          <Link to="/sendpicture">Envoyer des Photos sur l'écran</Link>
        </button>
      </div>
    </>
  );
}

export default Bapteme;
