import Collapse from "../../components/collapse/Collapse";
import { useEffect } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function CV() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `CV`;
  });

  return (
    <>
      <Header />
      <div className="centerPages CV">
        <h2>Expérience</h2>
        <Collapse
          title="Livreur"
          date="De Septembre 2021 à Mai 2022"
          lieux="Groupe La Poste"
          content="Contrat d'apprentissage en tant que livreur colis"
        />
        <Collapse
          title="Agent de production"
          date="De Septembre 2018 à Décembre 2019"
          lieux=" VETA FRANCE"
          content=" Posté en contrôle qualité"
        />

        <h2>Formation</h2>
        <Collapse
          title="Formation d'integrateur Web"
          date="De Mars 2023 à Février 2024"
          lieux="Formation à domicile"
          content="Formation d'intégrateur Web visant à apprendre le métier de développeur web"
        />
        <Collapse
          title="Formation CAO/DAO"
          date="De Novembre 2020 à Décembre 2020"
          lieux="ID Formation de Béthune"
          content="Formation de dessinateur industriel"
        />
        <Collapse
          title="BTS CRSA"
          date="De Septembre 2014 à Juin 2016"
          lieux="Lycée Carnot de Bruay-La-Buissière"
          content="BTS Conception Réalisation de Système Automatisé"
        />

        <Collapse
          title="BAC STI2D"
          date="De Septembre 2011 à Juin 2014"
          lieux="Lycée Carnot de Bruay-La-Buissière"
          content="BAC Science Technologique de l'industrie et du Développement Durable"
        />

        <section className="competence">
          <h2>Compétence</h2>

          <div className="gridcompetence">
            <ul>
              <h4>Language de programmation :</h4>
              <li>HTML</li>
              <li>CSS</li>
              <li>JAVASCRIPT</li>
              <li>SASS</li>
              <li>REACT</li>
            </ul>
            <ul>
              <h4>Personnalité :</h4>
              <li>Être à l'écoute, faire preuve d'empathie.</li>
              <li>Faire preuve d'autonomie.</li>
              <li>Faire preuve de curiosité.</li>
              <li>
                Organiser son travail selon les priorités et les objectifs.
              </li>
            </ul>
            <ul>
              <h4>Langue :</h4>
              <li>Français : Natal</li>
              <li>Anglais : Intermédiaire</li>
              <li>Espagnol : Débutant</li>
            </ul>
          </div>
        </section>
        <section className="complementaire">
          <h2>Information complémentaire</h2>

          <div className="gridcomplementaire">
            <ul>
              <h4>Mobilité :</h4>
              <li>Permis B</li>
              <li>Transport en commun</li>
              <li>Vélo</li>
            </ul>
            <ul>
              <h4>Centre d'intérêt</h4>
              <li>Jardinage</li>
              <li>Lecture</li>
              <li>Vélo</li>
            </ul>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default CV;
