import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function Error() {
  useEffect(() => {
    <link rel="shortcut icon" type="image/png" href="/LOGO.png" />;

    document.title = `Erreur`;
  });
  return (
    <>
      <Header />
      <div className="centerError">
        <h1>La page que vous demandez n'existe pas ou plus !</h1>
        <h2>Deux choix s'offre a vous :</h2>

        <Link to="/">
          <h4>Retour sur la page d'Accueil</h4>
        </Link>
        <Link to="/CV">
          <h4>Allez voir le CV</h4>
        </Link>
      </div>
      <Footer />
    </>
  );
}

export default Error;
